import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Flex, Stack } from '../../components/Core';
import Hero from '../../components/Hero';
import { HERO_TYPE } from '../../components/Hero/constants';
import InstituteContactForm from '../../components/InstituteContactForm';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import StyledLink from '../../components/StyledLink';
import Text from '../../components/Text';
import { createImageWithArtDirection } from '../../utils/serializeImageForImageComponent';

type IContactInstitutePageTemplate = 
  PageProps<Queries.PageContactInstituteQuery, IPageContext>;

const ContactInstitutePageTemplate: FCWithClassName<
  IContactInstitutePageTemplate
> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuContactInstitute' in data.wpPage.template ? data.wpPage.template.contenuContactInstitute : null;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const serializedContactFormQuestions =
    pageTemplateData.contactInstituteSectionContacter.questions?.map(
      (question) => {
        const options = question.choixDisponibles?.map((choice) => ({
          checked: choice.checked,
          label: choice.label,
          name: choice.name,
        }));
        return {
          label: question.label,
          name: question.slug,
          ...(options ? { options } : {}),
          required: question.obligatoire,
          ...(question.rows ? { rows: question.rows } : {}),
          ...(question.toggletext
            ? { toggleButtonText: question.toggletext }
            : {}),
          type: question.questionType as QUESTION_TYPE,
        };
      }
    );
  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: pageData.featuredImage?.node,
    mobileImage: pageData.hero.imageMobile,
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
      theme="institute"
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap" justify="center">
        <Hero
          type={HERO_TYPE.STATIC}
          couleurTitre={pageData.hero.couleurTitre}
          heading={pageData.hero.titre}
          tagline={pageData.hero.tagline}
          backgroundImage={heroImageWithArtDirection}
        />
        <SectionWithTitle
          heading={
            pageTemplateData.contactInstituteSectionContacter
              .titre ?? undefined
          }
        >
          <Flex direction={{ base: 'column', lg: 'row' }} className="w-full">
            <InstituteContactForm
              feedbackMessages={{
                error:
                  pageTemplateData
                    .contactInstituteSectionContacter.errorMsg,
                success:
                  pageTemplateData
                    .contactInstituteSectionContacter.successMsg,
              }}
              questions={serializedContactFormQuestions}
              submitButtonLabel={
                pageTemplateData
                  .contactInstituteSectionContacter.texteBoutonValidation
              }
            />
            <Flex direction="column" className="px-0 lg:px-16">
              <Text className="mb-4">
                {pageTemplateData
                  .contactInstituteSectionContacter.text || ''}
              </Text>
              <Text>
                {pageTemplateData
                  .contactInstituteSectionContacter.phraseEmailDeContact || ''}
              </Text>
              <StyledLink
                shouldObfuscate
                isEmail
                to={
                  pageTemplateData
                    .contactInstituteSectionContacter.emailDeContact
                }
              >
                <Text>
                  {pageTemplateData
                    .contactInstituteSectionContacter.emailDeContact || ''}
                </Text>
              </StyledLink>
            </Flex>
          </Flex>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default ContactInstitutePageTemplate;

export const query = graphql`
  query PageContactInstitute($id: String) {
    wpPage(id: { eq: $id }) {
      link
      seo {
        ...SEOFragment
      }
      language {
        locale
        slug
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      hero {
        couleurTitre
        tagline
        titre
        imageMobile {
          ...MediaItemFragment
          localFile {
            ...MobileHeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageContactInstitute {
          contenuContactInstitute {
            contactInstituteSectionContacter {
              emailDeContact
              errorMsg
              phraseEmailDeContact
              questions {
                ... on WpTemplate_PageContactInstitute_Contenucontactinstitute_ContactInstituteSectionContacter_Questions_Question {
                  label
                  obligatoire
                  questionType
                  rows
                  slug
                  toggletext
                  choixDisponibles {
                    checked
                    label
                    name
                  }
                }
              }
              successMsg
              text
              texteBoutonValidation
              titre
            }
          }
        }
      }
    }
  }
`;
